<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <circle style="fill:#A0DBFD;" cx="245.292" cy="256.316" r="199.109" />
    <path
      style="fill:#C1E9FD;"
      d="M419.692,265.169c-4.889,0-8.852-3.963-8.852-8.852c0-91.284-74.265-165.551-165.551-165.551
	c-4.889,0-8.852-3.963-8.852-8.852c0-4.889,3.963-8.852,8.852-8.852c101.046,0,183.254,82.207,183.254,183.254
	C428.544,261.206,424.581,265.169,419.692,265.169z"
    />
    <path
      style="fill:#96D5F6;"
      d="M150.529,397.106c-77.755-77.755-77.755-203.823,0-281.578
	c33.014-33.014,74.738-52.003,117.775-56.983c-58.325-6.749-119.062,12.24-163.803,56.983c-77.755,77.755-77.755,203.823,0,281.578
	c44.741,44.741,105.478,63.73,163.803,56.983C225.268,449.109,183.543,430.122,150.529,397.106z"
    />
    <path
      style="fill:#FED159;"
      d="M350.541,424.787c-43.196,26.652-96.56,36.827-149.853,24.206
	C94.101,423.748,28.161,316.879,53.404,210.292s132.114-172.527,238.7-147.284c104.606,24.775,170.052,128.17,148.587,232.758
	l-24.395-5.777l28.222,94.215L512,312.656l-24.568-5.819C514.996,176.44,433.577,47.178,303.168,16.291
	C170.781-15.063,38.042,66.841,6.687,199.227s50.549,265.126,182.936,296.481c66.193,15.677,132.475,3.04,186.128-30.065"
    />
    <g>
      <rect
        x="259.136"
        y="247.464"
        style="fill:#65B4BB;"
        width="84.951"
        height="17.704"
      />
      <rect
        x="236.44"
        y="181.37"
        style="fill:#65B4BB;"
        width="17.704"
        height="61.103"
      />
    </g>
    <g>
      <rect
        x="236.44"
        y="373.244"
        style="fill:#57A3A7;"
        width="17.704"
        height="49.384"
      />
      <rect
        x="236.44"
        y="89.11"
        style="fill:#57A3A7;"
        width="17.704"
        height="49.384"
      />
    </g>
    <g>
      <rect
        x="309.664"
        y="380.453"
        transform="matrix(-0.5 -0.866 0.866 -0.5 146.3824 863.123)"
        style="fill:#65B4BB;"
        width="25.371"
        height="17.703"
      />

      <rect
        x="155.572"
        y="113.583"
        transform="matrix(-0.5 -0.866 0.866 -0.5 146.3568 329.3676)"
        style="fill:#65B4BB;"
        width="25.371"
        height="17.703"
      />

      <rect
        x="366.011"
        y="324.043"
        transform="matrix(-0.866 -0.5 0.5 -0.866 540.2047 810.5407)"
        style="fill:#65B4BB;"
        width="25.371"
        height="17.703"
      />

      <rect
        x="99.176"
        y="169.976"
        transform="matrix(-0.866 -0.5 0.5 -0.866 119.3212 389.6273)"
        style="fill:#65B4BB;"
        width="25.371"
        height="17.703"
      />
    </g>
    <g>
      <rect
        x="362.668"
        y="247.016"
        style="fill:#57A3A7;"
        width="49.384"
        height="17.704"
      />
      <rect
        x="78.534"
        y="247.016"
        style="fill:#57A3A7;"
        width="49.384"
        height="17.704"
      />
    </g>
    <g>
      <rect
        x="369.897"
        y="166.137"
        transform="matrix(-0.5 -0.866 0.866 -0.5 413.2643 596.2393)"
        style="fill:#65B4BB;"
        width="17.703"
        height="25.371"
      />

      <rect
        x="103.021"
        y="320.217"
        transform="matrix(-0.5 -0.866 0.866 -0.5 -120.4893 596.2415)"
        style="fill:#65B4BB;"
        width="17.703"
        height="25.371"
      />

      <rect
        x="313.47"
        y="109.751"
        transform="matrix(-0.866 -0.5 0.5 -0.866 540.2384 389.6342)"
        style="fill:#65B4BB;"
        width="17.703"
        height="25.371"
      />

      <rect
        x="159.385"
        y="376.608"
        transform="matrix(-0.866 -0.5 0.5 -0.866 119.2809 810.5499)"
        style="fill:#65B4BB;"
        width="17.703"
        height="25.371"
      />
    </g>
    <circle style="fill:#57A3A7;" cx="245.292" cy="256.316" r="22.695" />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
